import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import "./faq.scss"

import SectionHeader from "../sectionHeader/sectionHeader"

const faqs = [
  {
    q: "What is Wisdom Words?",
    a:
      "Wisdom words publishing is a publishing platform for authors. We provide them a guiding hand to make their publishing process effortless. ",
  },
  {
    q: "What is the wisdom words publishing process?",
    a:
      "Just handover your hard-worked manuscript and we will give you a professional book within a month. It’s that easy. Everything is on us, like a fine editing, an eye-catching cover and attractively designed interior, with addition to promotion and marketing facility. ",
  },
  {
    q: "In which format do you accept the manuscript?",
    a:
      "Basically, we prefer manuscripts in Word format. In case, if you have it in any other format, we don’t reject that either. ",
  },
  // {
  //   q:
  //     "If my manuscript has some mistakes like, typing or in sentence making or small grammatical issues, will that be taken care of by you before a book goes to a print?",
  //   a:
  //     "For this, we suggest you to opt for one of our editing services. Depending upon the editing services chosen, the manuscript is worked upon. And then the correction and the changes will be done, once you approve them and are happy with the changes.",
  // },
  // {
  //   q: "How many copies of a book will you print?",
  //   a:
  //     "Wisdom words manage inventory services very well. So, be assured about your book copies, there is no chance that the book will ever be out of stock. And if the first edition of the book is completely sold off, then we offer the print on demand facility and the book then goes to digital POD. After the stock of the first edition of the books sold off, it’s a choice of the author, whether he/she wants to print the second edition of the book in a lot, or wants to opt for the POD facility. We have both the ways open.",
  // },
  // {
  //   q: "Who owns and retains the rights of the book?",
  //   a:
  //     "The book is the output of the author’s hardwork and we are just the facilitator. Hence there is no doubt about who should hold the rights of the book. It will always be you (authors), when it comes to retaining the rights of the books.",
  // },
  // {
  //   q: "In which format do you accept the manuscript?",
  //   a:
  //     "Basically, we prefer manuscripts in Word format. In case, if you have it in any other format, we don’t reject that either. ",
  // },
]

const Faq = () => {
  return (
    <section className="section faq-section">
      <SectionHeader head={"Frequently asked questions"} />
      <div className="faq-section-wrapper">
        {faqs.map(faq => {
          const { q: question, a: answer } = faq
          return (
            <div className="one-faq">
              <div className="faq-question">{question}</div>
              <div className="faq-answer">{answer}</div>
            </div>
          )
        })}
      </div>
      <br />
      <br />
      <br />
      <center>
        <AniLink
          cover
          bg="#ffffff"
          direction="up"
          className="button lined"
          to="/frequently-asked-questions"
        >
          View all
        </AniLink>
      </center>
    </section>
  )
}
export default Faq
