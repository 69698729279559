import React, { useRef } from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import BooksPage from "../components/booksPage/BooksPage.js"
import SEO from "../components/seo"

import {
  isavasyopanishad,
  sawalSamayKe,
  reflectingRipples,
} from "../data/books"

const SawalSamayKe = () => {
  return (
    <>
      <SEO
        title="Reflecting Ripples"
        description="Our publishing process strives to transform your writing into a  beautifully designed book, which then marketed to the right and relevant readers."
      />
      <BooksPage book={reflectingRipples} />
    </>
  )
}
export default SawalSamayKe
