import React, { useState } from "react"
import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import "./navbar-mobile.scss"
import burger from "../../images/icons/burger.svg"
import logo from "../../images/logo.svg"
//TODO: search for seo friendly navbar and label tags to put here
const NavbarMobile = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const toggleMenu = () => {
    const isOpen = isMenuOpen
    setIsMenuOpen(!isOpen)
  }
  return (
    <div className="mobile-menu-wrapper">
      <div className={`reveal-menu ${isMenuOpen ? "open" : "close"}`}>
        <ul className="center-nav nav-ul">
          {/* <li className="">
            <Link className="hover-state" to="/">
              About
            </Link>
          </li> */}
          <li className="">
            <AniLink
              cover
              bg="#ffffff"
              direction="up"
              className="hover-state"
              to="/"
              activeClassName="active"
              onClick={toggleMenu}
            >
              Home
            </AniLink>
          </li>
          <li className="">
            <AniLink
              cover
              bg="#ffffff"
              direction="up"
              className="hover-state"
              to="/our-solutions"
              activeClassName="active"
              onClick={toggleMenu}
            >
              Our Services
            </AniLink>
          </li>

          <li className="">
            <AniLink
              cover
              bg="#ffffff"
              direction="up"
              className="hover-state"
              to="/process"
              activeClassName="active"
              onClick={toggleMenu}
            >
              Our Process
            </AniLink>
          </li>
          <li className="">
            <AniLink
              cover
              bg="#ffffff"
              direction="up"
              className="hover-state"
              to="/about-us"
              activeClassName="active"
              onClick={toggleMenu}
            >
              About Us
            </AniLink>
          </li>
          <li className="">
            <AniLink
              cover
              bg="#ffffff"
              direction="up"
              className="hover-state"
              to="/frequently-asked-questions"
              activeClassName="active"
              onClick={toggleMenu}
            >
              FAQs
            </AniLink>
          </li>
          <li className="">
            <a
              className="hover-state"
              href="https://medium.com/@wisdomwords"
              target="_blank"
              onClick={toggleMenu}
            >
              Blog
            </a>
          </li>
          <li className="">
            <AniLink
              cover
              bg="#ffffff"
              direction="up"
              className="hover-state"
              to="/contact-us"
              activeClassName="active"
              onClick={toggleMenu}
            >
              Contact Us
            </AniLink>
          </li>
          {/* <li className="">
            <AniLink
          cover
          bg="#ffffff"
          direction="up"
              className="button filled"
              to="/contact-us"
              activeClassName="active"
            >
              Contact Us
            </AniLink>
          </li> */}
        </ul>
      </div>
      <div className="header-wrapper">
        <AniLink cover bg="#ffffff" direction="up" to="/">
          <img src={logo} />
        </AniLink>
        <button
          className={`burger-lines ${isMenuOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <div className="line-one line" />
          <div className="line-two line" />
        </button>
        {/* <img onClick={toggleMenu} src={burger} /> */}
      </div>
    </div>
  )
}

export default NavbarMobile
