import React, { useState, useEffect } from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"


import "./contactForm.scss"

import SectionHeader from "../sectionHeader/sectionHeader"
import image from "../../images/phone-call.svg"

const SubHeading = () => {
  return (
    <>
      or call us <OutboundLink href="tel:+91 90249 31422">+91 90249 31422</OutboundLink>
    </>
  )
}

const ContactForm = ({ subHeading = false, inNewPageTop }) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [query, setQuery] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const [error, setError] = useState({
    errorType: "",
    errorValue: "--",
  })
  const { errorType, errorValue } = error

  const handleChange = (which, event) => {
    const value = event.target.value
    switch (which) {
      case "name":
        setName(value)
        break
      case "email":
        setEmail(value)
        break
      case "phone":
        setPhone(value)
        break
      case "query":
        setQuery(value)
        break
      default:
        break
    }
  }
  const checkProperties = obj => {
    const nulls = []
    for (var key in obj) {
      if (obj[key] === null || obj[key] === "") nulls.push(key)
    }
    return nulls
  }

  const handleSubmit = event => {
    event.preventDefault()
    setError({
      errorValue: "--",
      errorType: "",
    })
    // debugger
    const nulls = checkProperties({
      name,
      email,
      phone,
      query,
    })
    if (nulls.length) {
      setError({
        errorType: "error",
        errorValue: (
          <div>
            {`fields ${nulls.join(", ")} are `}
            <b>required</b>
          </div>
        ),
      })
      return
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name,
        email,
        phone,
        query,
      }),
      redirect: "manual",
    }
    fetch(
      "https://send.pageclip.co/ph0xTijjAR7ZmDYwxtu9MybpyF4aK3A6/Contactus",
      requestOptions
    )
      .then(response => {
        // console.log("lol")
        if (response.redirected) {
          // alert("Unexpected redirect")
        } else {
          // alert("working")
        }

        // setName("")
        // setEmail("")
        // setPhone("")
        // setQuery("")
        setIsLoading(false)
        setIsSent(true)

        // const data = await response.json()

        // check for error response
        // if (!response.ok) {
        //   // get error message from body or default to response status
        //   const error = (data && data.message) || response.status
        //   return Promise.reject(error)
        // }

        // this.setState({ postId: data.id })
      })
      .catch(error => {
        // this.setState({ errorMessage: error.toString() })
        console.error("There was an error!", error)
        setError({
          errorValue:
            "sorry, something went wrong!, please try again or call us at : +91 90249 31422",
          errorType: "error",
        })
      })
  }
  return (
    <section className={`section ${inNewPageTop && "in-new-page-top"}`}>
      <SectionHeader
        head={"Let’s get in touch"}
        subHead={subHeading && <SubHeading />}
      />
      <div className="contact-form-wrapper">
        <img className="form-image" src={image} />
        <div className="form-separator" />
        <form
          onSubmit={handleSubmit}
          className={`form-wrapper ${isSent ? "sent" : ""}`}
        >
          <label className="one-input">
            <input
              className="contact-input"
              type="text"
              name="name"
              value={name}
              onChange={handleChange.bind(null, "name")}
            />
            <div className={`label-div ${name || name !== "" ? "filled" : ""}`}>
              Your name
            </div>
            {/* the order matters here */}
          </label>
          <label className="one-input">
            <input
              className="contact-input"
              type="email"
              name="email"
              value={email}
              onChange={handleChange.bind(null, "email")}
            />
            <div
              className={`label-div ${email || email !== "" ? "filled" : ""}`}
            >
              Your email address
            </div>
            {/* the order matters here */}
          </label>
          <label className="one-input">
            <input
              className="contact-input"
              type="number"
              name="phone"
              value={phone}
              onChange={handleChange.bind(null, "phone")}
            />
            <div
              className={`label-div ${phone || phone !== "" ? "filled" : ""}`}
            >
              Your phone number
            </div>
            {/* the order matters here */}
          </label>
          <label className="one-input nomargin">
            <textarea
              type="text"
              className="contact-input"
              name="query"
              onChange={handleChange.bind(null, "query")}
            >
              {query}
            </textarea>
            <div
              className={`label-div ${query || query !== "" ? "filled" : ""}`}
            >
              Your query <small>(optional)</small>
            </div>
            {/* the order matters here */}
          </label>
          <div className={`error-place ${errorType}`}>{errorValue}</div>
          <button type="submit" className="faq-view-more button lined small">
            Submit
          </button>

          <div className={`sent-message ${isSent ? "visible" : ""}`}>
            <div className="title">Thank you!</div>
            <div className="message">
              we will be in touch <br />
              shortly
            </div>
          </div>
        </form>
      </div>
    </section>
  )
}
export default ContactForm
