import { Link } from "gatsby"
import React, { useState, useEffect } from "react"

import "./sectionHeader.scss"

const SectionHeader = ({ head, subHead, extraStyle = {} }) => {
  return (
    <div style={extraStyle}>
      <h2 className={`sectionHeader ${subHead && "contains-sub-heading"}`}>
        {head}
      </h2>
      <h3 className="sectionSubHeader">{subHead}</h3>
    </div>
  )
}
export default SectionHeader
