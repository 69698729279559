import React from "react"
// import "./books.scss"
// import SectionHeader from "../sectionHeader/sectionHeader"
import Layout from "../components/layout"
import SEO from "../components/seo"

// import bookIsha from "./asset/book-isha.png"
// import bookSawal from "./asset/book-sawal.png"
import ExtraSection from "../components/extraPages/extraPages"

const Faqs = () => {
  return (
    <>
      <SEO title="Privacy Policy" description="our Privacy Policy" />
      <br />
      <br />
      <ExtraSection page="privecypolicy" />
      {/* <ContactForm subHeading inNewPageTop /> */}
    </>
  )
}
export default Faqs
