import { Link } from "gatsby"
import React, { useState, useEffect } from "react"

import "./mainProblems.scss"

import problemImage1 from "../../images/problems/problem1.svg"

import chart from "./asset/chart.svg"
import paper from "./asset/paper.svg"
import techflower from "./asset/techflower.svg"

import SectionHeader from "../sectionHeader/sectionHeader"
//TODO: correct names of file
//TODO: use svg wrapper instead of img tag

const problems = [
  {
    image: chart,
    head: "A fully Transparent Publishing solution",
    text:
      "Lorem Ipsom text, Supporting text swill go Here Lorem Ipsom text, Supporting text swill go Here",
  },
  {
    image: techflower,
    head: "Technology to ensure Quality Assurance",
    text:
      "Lorem Ipsom text, Supporting text swill go Here Lorem Ipsom text, Supporting text swill go Here",
  },
  {
    image: paper,
    head: "Reaching right and relevant audience",
    text:
      "Lorem Ipsom text, Supporting text swill go Here Lorem Ipsom text, Supporting text swill go Here",
  },
]

const MainProblems = () => {
  return (
    <section className="section main-problems-wrapper-section">
      <SectionHeader
        head={"Publishing done differently"}
        // subHead={"subHead"}
      />
      <div className="main-problems-wrapper">
        {problems.map(problem => {
          const { image, head, text } = problem
          return (
            <div className="problem-wrapper">
              <img className="prob-image" src={image} />
              <div className="prob-head">{head}</div>
              {/* <div className="prob-text">{text}</div> */}
            </div>
          )
        })}
      </div>
    </section>
  )
}
export default MainProblems
