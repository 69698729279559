/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import NavbarDesktop from "../components/navbar/navbar-desktop"
import NavbarMobile from "../components/navbar/navbar-mobile"
import logo from "../images/logo.svg"
import burger from "../images/icons/burger.svg"
import whatsappSvg from "../images/icons/whatsapp.svg"

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect"
import Footer from "../components/footer/footer"

import Header from "./header"
import "./layout.scss"

const Layout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const toggleMenu = () => {
    const isOpen = isMenuOpen
    setIsMenuOpen(!isOpen)
  }
  return (
    <>
      <header>
        <NavbarDesktop />
        <NavbarMobile />
      </header>
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}

      {/* {isMobile && (
        <Link to="/" className="logo">
          <img src={logo} />
        </Link>
      )} */}

      <main>{children}</main>
      {/* <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer> */}
      <a
        href="https://api.whatsapp.com/send?phone=919024931422&text=Hi"
        target="_blank"
        className="whatsapp-chat-button"
      >
        <img src={whatsappSvg} />
      </a>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
