import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import SectionHeader from "../sectionHeader/sectionHeader"
import tickSelected from "../../images/tick-selected.svg"

import "./planTable.scss"

import planTableData from "../../data/planTableData"

const PricingStructureColumn = ({ planData }) => {
  const {
    mrp,
    cost,
    subTitle,
    publishingServices,
    postPublishingServices,
  } = planData

  return (
    <div className="plan-column-wrapper">
      <div className="cost-data">
        <div className="cost">₹{cost}</div>
        <div className="mrp">₹{mrp}</div>
      </div>
      <div className="sub-title">{subTitle}</div>
      <div className="publishing-services-wrapper services-list">
        <div className="title">Publishing</div>
        <div className="list">
          {publishingServices.map(publishingService => {
            return (
              <div className="publishing-service service">
                <img src={tickSelected} />
                <>{publishingService}</>
              </div>
            )
          })}
        </div>
      </div>
      <div className="publishing-services-wrapper services-list">
        <div className="title">Post Publishing</div>
        <div className="list">
          {postPublishingServices.map(publishingService => {
            return (
              <div className="publishing-service service">
                <img src={tickSelected} />
                <>{publishingService}</>
              </div>
            )
          })}
        </div>{" "}
      </div>
    </div>
  )
}

const PlanTable = () => {
  return (
    <div className="plan-table-wrapper">
      <SectionHeader
        head={<>Explore plan’s that fits all your needs</>}
        subHead={<>Whatever your budget is, you will get these things</>}
      />
      <div className="pricing-table">
        <PricingStructureColumn planData={planTableData.basic} />
        <PricingStructureColumn planData={planTableData.standard} />
        <PricingStructureColumn planData={planTableData.advanced} />
        <PricingStructureColumn planData={planTableData.premium} />
      </div>
    </div>
  )
}
export default PlanTable
