import React from "react"

const planTableData = {
  basic: {
    mrp: "14,999",
    cost: "8,000",
    subTitle: (
      <>
        Suitable for writers who just want to publish there book & market by
        themselves.
      </>
    ),
    publishingServices: [
      <>Publishing Manager Assistance</>,
      <>ISBN & Barcode</>,
      <>Author Copyright</>,
      <>PaperBack Binding</>,
      <>B/W Color</>,
      <>Std Book Formatting</>,
      <>Formatting Proofing</>,
      <>Basic Cover Design</>,
      <>Cover Proofing</>,
      <>Digital Proofing</>,
    ],
    postPublishingServices: [
      <>Online Distribution in India</>,
      <>Std. Book Promotion</>,
      <>5 free Author Copies</>,
    ],
  },
  standard: {
    mrp: "49,999",
    cost: "24,000",
    subTitle: (
      <>
        Suitable for writers who just want to publish there book & market by
        themselves.
      </>
    ),
    publishingServices: [
      <>Publishing Manager Assistance</>,
      <div className="spacial">Std. E-book version of Book</div>,
      <>ISBN & Barcode</>,
      <>Author Copyright</>,
      <>PaperBack or Stitched Binding</>,
      <>B/W Color</>,
      <>Basic Editing</>,
      <>Professional Layout Design</>,
      <>Editing Proofing</>,
      <>Formatting Proof</>,
      <>Cover Proofing</>,
      <>Professional Cover Design</>,
      <>Digital Proofing</>,
      ,
    ],
    postPublishingServices: [
      <>Online Distribution in India</>,
      <>Ebook Global Distribution</>,
      <>Facebook Ads for Book</>,
      <>Instagram Ads for Book</>,
      <>online Book Review</>,
      <>10 free Author Copies</>,
    ],
  },
  advanced: {
    mrp: "79,999",
    cost: "49,000",
    subTitle: (
      <>
        Suitable for writers who just want to publish there book & market by
        themselves.
      </>
    ),
    publishingServices: [
      <>Publishing Manager Assistance</>,
      <>Book Critic Assistance</>,
      <div className="spacial">Professional E-book version of Book</div>,
      <>ISBN & Barcode</>,
      <>Author Copyright</>,
      <>PaperBack or Stitched or Hardback Binding</>,
      <>B/W + 10 Pages in Color</>,
      <>Copy Editing</>,
      <>Advanced Layout Design with Premium line Illustrations</>,
      <>Editing Proofing</>,
      <>Formatting Proof</>,
      <>Advanced Cover Design</>,
      <>Cover Proofing</>,
      <>Digital Proofing</>,
      <div className="spacial">Std. Video Trailer for Book</div>,
      <div className="spacial">Professional Ebook</div>,
    ],
    postPublishingServices: [
      <>Online Global Distribution</>,
      <>E-book Global Distribution</>,
      <>Facebook + Instagram Business Account Setup</>,
      <>Goodreads Author Page Setup</>,
      <>Online Author Interview</>,
      <div className="spacial">Std. Author Website</div>,

      <>Book Giveaway Campaign</>,
      <>Online Book Review</>,
      <>Facebook + Instagram Ads for Book</>,
      <>Amazon Sponsored Ad for Book</>,
      <>SMS + E-Mail Campaign</>,
      <>20 free Author Copies</>,
    ],
  },
  premium: {
    mrp: "1,41,999",
    cost: "90,000",
    subTitle: (
      <>
        Suitable for writers who just want to publish there book & market by
        themselves.
      </>
    ),
    publishingServices: [
      <>Publishing Manager Assistance</>,
      <>Book Critic Assistance</>,
      <>Book Mentor Assistance</>,
      <div className="spacial">Professional E-book version of Book</div>,
      <>ISBN & Barcode</>,
      <>Author Copyright with Registration</>,
      <>PaperBack or Stitched or Hardback or Cardboard Binding</>,
      <>B/W or All Pages in Color</>,
      <>Line Editing</>,
      <>Advanced Layout Design with Premium line Illustrations</>,
      <>Editing Proofing</>,
      <>Formatting Proof</>,
      <>Premium Cover Design</>,
      <>Cover Proofing</>,
      <>Digital Proofing</>,
      <div className="spacial">Advanced Video Trailer for Book</div>,
    ],
    postPublishingServices: [
      <>Online Global Distribution</>,
      <>E-book Global Distribution</>,
      <>Bookstore Distribution</>,
      <>Facebook + Instagram Business Account Setup</>,
      <>Goodreads Author Page Setup</>,
      <>Online Author Interview</>,
      <>Press Release</>,
      <div className="spacial">Professional Author Website</div>,

      <>Book Giveaway Campaign</>,
      <>Online Book Review</>,
      <>Facebook + Instagram Ads for Book</>,
      <>Youtube Ads for Book</>,
      <>Google and Amazon SEO by Profession</>,
      <>Amazon Sponsored Ad for Book</>,
      <>SMS + E-Mail Campaign</>,
      <>25 free Author Copies</>,
    ],
  },
}

export default planTableData
