import { Link } from "gatsby"
import React, { useState, useEffect } from "react"

import "./process.scss"

import problemImage1 from "../../images/process/process1.svg"

import arrow from "../../images/misc/arrow.svg"
import SectionHeader from "../sectionHeader/sectionHeader"

import books from "./asset/books.svg"
import dash from "./asset/dash.svg"
import docs from "./asset/docs.svg"

const processes = [
  {
    image: docs,
    head: "Pre-Publishing",
    text: (
      <div>
        Before publishing a book, we immensely realize the importance of a
        thorough understanding of manuscript and building rapport with our
        writers.
      </div>
    ),
  },
  {
    image: books,
    head: "Publishing",
    text:
      "Our dedicated and professional team will transform your content into a professional book passionately.",
  },
  {
    image: dash,
    head: "Post-Publishing",
    text:
      "Your  journey doesn't terminate with publishing a book. Our post-publishing process is to ensure the promotion and distribution of your book with utter satisfaction.",
  },
]

const Process = () => {
  return (
    <section className="section">
      <SectionHeader head={"Our well defined publishing process"} />
      <div className="main-process-wrapper">
        {processes.map(process => {
          const { image, head, text } = process
          return (
            <div className="process-wrapper">
              <img className="proc-image" src={image} />
              <div className="proc-content">
                <div className="proc-head">{head}</div>
                <div className="proc-text">{text}</div>
                <Link className="proc-link" to="/process">
                  Learn more
                  <img src={arrow} />
                </Link>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}
export default Process
