import React, { useState, useEffect } from "react"

import "./processSteps.scss"
// import image from "../../images/phone-call.svg"

import process1 from "../../pages/asset/process-1.svg"
import process2 from "../../pages/asset/process-2.svg"
import process3 from "../../pages/asset/process-3.svg"
const image = "https://via.placeholder.com/200/bdc3c7/bdc3c7?Text=wisdom"

const processSteps = [
  {
    title: "Pre-Publishing",
    subTitle:
      "Before publishing a book, we immensely realize the importance of a thorough understanding of manuscript and building rapport with our writers. ",
    image: process1,
    steps: [
      {
        number: "01",
        title: "Connect with us",
        subTitle:
          "Be easy in discussing your publishing related queries and concerns with our professionals. ",
      },
      {
        number: "02",
        title: "Discussion on the synopsis",
        subTitle:
          "Based on the synopsis, we recommend you about adequate editing plans, publishing packages and marketing strategies.",
      },
      {
        number: "03",
        title: "Agreement and Manuscript submission",
        subTitle:
          "Agreement is signed to shield the writers. Subsequently, then you submit the manuscript.",
      },
      {
        number: "04",
        title: "Credential allocation to track your book publication progress",
        subTitle:
          "Wisdom word publishing is a publishing platform for authors. We provide them a guiding hand to make their publishing process effortless. ",
      },
    ],
  },
  {
    title: "Publishing",
    subTitle:
      "Our dedicated and professional team will transform your content into a professional book passionately.",
    image: process2,
    steps: [
      {
        number: "05",
        title: "Editing",
        subTitle:
          "Manuscript is read with an eye to check for spelling, grammar, punctuation, correct word usage, proper text follow, content organization, design, style and other minute details.",
      },
      {
        number: "06",
        title: "Interior Page Designing",
        subTitle:
          "Every page of the manuscript is vigilantly designed with decorous fonts, book size, page margins as per book genre, standard style and convention. ",
      },
      {
        number: "07",
        title: "Proofreading",
        subTitle:
          "Edited manuscript is eventually read to ensure no error remains and to uncover basic errors in spelling, grammar and punctuations. ",
      },
      {
        number: "08",
        title: "Cover designing",
        subTitle:
          "We design a cover that so accurately reflects the substance of your book content. ",
      },
      {
        number: "09",
        title: "ISBN and Barcode",
        subTitle: "We manage the ISBN and barcode for our writers.",
      },
      {
        number: "10",
        title: "Seeking for consent",
        subTitle:
          "We share a pre-publishing copy to seek for the final approval and to pleased our writers. ",
      },
      {
        number: "11",
        title: "Printing",
        subTitle:
          "Ensured to reward you with a fascinating book by adhering to the scheduled time.",
      },
    ],
  },
  {
    title: "Post-Publishing",
    subTitle:
      "Your  journey doesn’t terminate with publishing a book. Our post-publishing process is to ensure the promotion and distribution of your book with utter satisfaction.",
    image: process3,
    steps: [
      {
        number: "12",
        title: "Marketing",
        subTitle:
          "Our team plans the campaign and exerts email, SMS, Ads and other game plans to outreach millions. ",
      },
      {
        number: "13",
        title: "Creative for promotion",
        subTitle:
          "Hand-pick the creative from numbers of alluring creatives available on Author Dashboard and promote your book.",
      },
      {
        number: "14",
        title: "Book listing on online portals",
        subTitle:
          "We list your book on diverse online portals like Amazon, Snapdeal, Flipkart and many others. ",
      },
      {
        number: "15",
        title: "Sales and Distribution",
        subTitle:
          "Books are sold and distributed physically. We handle order processing, inventory control, packaging and logistics. ",
      },
      {
        number: "16",
        title: "Building author pages",
        subTitle:
          "Having an author page is imperative, thus we keenly focus on creating the author pages on vivid portals like Goodreads, Amazon and others.",
      },
      {
        number: "17",
        title: "Remitting Royalty",
        subTitle: "Adore complete profit for a lifetime on sale of each book. ",
      },
    ],
  },
]

const ProcessIntroBlock = ({ title, subTitle, image }) => {
  return (
    <div className={`process-intro ${title}`}>
      <div className="intro-text">
        <h3>{title}</h3>
        <p>{subTitle}</p>
      </div>
      <img src={image} className="intro-image" />
    </div>
  )
}
const ProcessSteps = ({ title, subTitle, indexText }) => {
  return (
    <div className="process-steps">
      <div className="step-index">{indexText}</div>
      <h4>{title}</h4>
      <p>{subTitle}</p>
    </div>
  )
}
const ProcessBlocks = () => {
  return (
    <div className="process-blocks-wrapper">
      {processSteps.map(processSteps => {
        const {
          title: introTitle,
          subTitle: introSubTitle,
          image: introImage,
          steps,
        } = processSteps
        return (
          <div className="process-step-joiner">
            {[
              <ProcessIntroBlock
                title={introTitle}
                subTitle={introSubTitle}
                image={introImage}
              />,
              ...[
                <div className="process-steps-wrapper">
                  {steps.map(step => {
                    const {
                      title: stepTitle,
                      subTitle: stepSubTitle,
                      number: stepNumber,
                    } = step
                    return (
                      <ProcessSteps
                        title={stepTitle}
                        subTitle={stepSubTitle}
                        indexText={stepNumber}
                      />
                    )
                  })}
                </div>,
              ],
            ]}
          </div>
        )
      })}
    </div>
  )
}
export default ProcessBlocks
