import React from "react"
// import "./books.scss"
// import SectionHeader from "../sectionHeader/sectionHeader"
import Layout from "../components/layout"

// import bookIsha from "./asset/book-isha.png"
// import bookSawal from "./asset/book-sawal.png"
import ExtraSection from "../components/extraPages/extraPages"
import SEO from "../components/seo"

const Faqs = () => {
  return (
    <>
      <SEO
        title="FAQs"
        description="We believe in nurturing the human relation and thus offer 24 by 7 support via call/Whatsapp/email from the pre-publishing to post publishing journey.- We believe in nurturing the human relation and thus offer 24 by 7 support from pre-publishing to post publishing journey."
      />
      <br />
      <br />
      <ExtraSection page="faqs" />
      {/* <ContactForm subHeading inNewPageTop /> */}
    </>
  )
}
export default Faqs
