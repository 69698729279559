import React, { useState, useEffect } from "react"
import Layout from "../components/layout"

import "./styles/process.scss"
import SectionHeader from "../components/sectionHeader/sectionHeader"
import SEO from "../components/seo"

import ContactForm from "../components/contactForm/contactForm"
import Faq from "../components/faq/faq"
import PlanSelector from "../components/plansSelector/plansSelector"
import ProcessBlocks from "../components/processSteps/processSteps"

const headerText = "Our well defined publishing process."
const subHeaderText = (
  <>
    Wondering about how will be the journey of getting the book published with
    us.
    <br /> Let's take a glimpse.
  </>
)

const ContactUsPage = () => {
  return (
    <>
      <SEO
        title="Book Publishing Process: wisdom words publishing"
        description="Our publishing process strives to transform your writing into a  beautifully designed book, which then marketed to the right and relevant readers."
      />
      <section className="section process-wrapper">
        <br />
        <br />
        <br />
        <SectionHeader head={headerText} subHead={subHeaderText} />
        <ProcessBlocks />
      </section>

      <ContactForm />
    </>
  )
}
export default ContactUsPage
