import React, { useRef } from "react"
import "./books.scss"
import { Link } from "gatsby"

import SectionHeader from "../sectionHeader/sectionHeader"
import Slider from "react-slick"
import bookIsha from "./asset/isha.png"
import bookSawal from "./asset/sawal.png"
import bookYaatra from "./asset/yaatra.png"
import bookRR from "./asset/reflecting.png"
// import RR_Front_Big from '../booksPage/asset/RR_Front_Big.png'
import RR_Front_Big from './asset/RR_Front_Big.png'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import arrowSvg from "../../images/misc/arrow.svg"

const books = [
  {
    title: "Ishavasyopanishad",
    subTitle: "Hindi Mein Geetantaran",
    amazonLink: "/isavasyopanishad-hindi-mein-geetantaran",
    image: bookIsha,
    author: "Prem Mohan Lakhotia",
    isReleased: true,
  },
  {
    title: "Sawal Samay Ke Jawab Zimmedari Ke",
    subTitle: "",
    image: bookSawal,
    amazonLink: "/sawal-samay-ke-jawab-zimmedari-ke",
    author: "Savita Lakhotia",
    isReleased: true,
  },
  {
    title: "Reflecting Ripples",
    subTitle: "Poetic Biography of Emotions and Experiences",
    image: RR_Front_Big,
    amazonLink: "/reflecting-ripples",
    author: "Prem Mohan Lakhotia",
    isReleased: true,
  },
  {
    title: "Yaatra",
    subTitle: "Udaan Jari Hai",
    image: bookYaatra,
    author: "Baghishayar",
    amazonLink: "",
    isReleased: false,
  },
  {
    title: "Prem Pyaar Preet",
    subTitle: "",
    image: bookRR,
    author: "Zeba Rasheed",
    amazonLink: "",
    isReleased: false,
  },
]

const sliderSettings = {
  dots: false,
  infinite: true,
  // adaptiveHeight: true,
  speed: 500,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  easing: "cubic-bezier(0.34, 1.56, 0.64, 1)",
  // variableWidth: true,
  // centerPadding : '200px'
  // centerMode : true
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const Books = () => {
  const sliderRef = useRef()

  const sliderChange = direction => {
    if (direction === "next") {
      sliderRef.current.slickNext()
    } else {
      sliderRef.current.slickPrev()
    }
    return
  }
  return (
    <section className="section book-section">
      <SectionHeader head={"Our Latest Books"} />
      <div className="books-wrapper">
        <div
          className="slider-arrow left"
          onClick={sliderChange.bind(this, "prev")}
        >
          <img src={arrowSvg} />
        </div>
        <Slider ref={sliderRef} {...sliderSettings}>
          {books.map(book => {
            const { image, title, subTitle, author, amazonLink } = book
            return (
              <div className="one-book" key={title}>
                <div className="book-image">
                  <img src={image} />
                  <div className="action-wrapper">
                    {amazonLink === "" ? (
                      <div className="action coming-soon">Releasing soon</div>
                    ) : (
                      <Link
                        className="action order-now"
                        to={amazonLink}
                        target="_blank"
                      >
                        Order Now
                      </Link>
                    )}
                  </div>
                </div>
                <div className="book-title">
                  {title}
                  {subTitle !== "" ? (
                    <div className="subtitle">{subTitle}</div>
                  ) : null}
                </div>
                <div className="book-author">{author}</div>
              </div>
            )
          })}
        </Slider>
        <div
          className="slider-arrow right"
          onClick={sliderChange.bind(this, "next")}
        >
          <img src={arrowSvg} />
        </div>
      </div>
    </section>
  )
}
export default Books
