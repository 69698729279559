import { Link } from "gatsby"
import React, { useState, useEffect } from "react"

import "./productFeatures.scss"

import cal from "./asset/cal.svg"
import list from "./asset/list.svg"
import paper from "./asset/paper.svg"
import piggy from "./asset/piggy.svg"
import shield from "./asset/shield.svg"
import sync from "./asset/sync.svg"

import SectionHeader from "../sectionHeader/sectionHeader"

const features = [
  {
    image: shield,
    head: "Reliability",
    text:
      "We ensure to adhere to the promises made in terms of quality, support, rights and royalty. ",
  },
  {
    image: list,
    head: "Transparency",
    text:
      "Track every little milestone of your publishing process by just sitting at home.",
  },
  {
    image: paper,
    head: "Quality Assurance",
    text:
      "Our team of professionals provides you with the delightful publication of books.",
  },
  {
    image: sync,
    head: "Seamless Process",
    text:
      "Your journey from pre-publishing to post publishing is entirely hassle-free.",
  },
  {
    image: cal,
    head: "Time Commitment",
    text: "Get your book published, prior to the committed schedule.",
  },
  {
    image: piggy,
    head: "Cost Effective",
    text:
      "Avail the supreme publishing and book promotion services at the lowest price.",
  },
]

const ProductFeatures = () => {
  return (
    <section className="section">
      <SectionHeader head={"What sets our publishing apart"} />
      <div className="main-features-wrapper">
        {features.map(feature => {
          const { image, head, text } = feature
          return (
            <div className="feature-wrapper">
              <img className="feat-image" src={image} />
              <div className="feat-content">
                <div className="feat-head">{head}</div>
                <div className="feat-text">{text}</div>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}
export default ProductFeatures
