import { Link } from "gatsby"
import React, { useState, useEffect } from "react"

import "./sectionHero.scss"

const SectionHero = ({ image, text }) => {
  return (
    <div className="section-hero">
      <div className="hero-image section-part">
        <img src={image} />
      </div>
      <div className="hero-text section-part">
        <div>{text}</div>
      </div>
    </div>
  )
}
export default SectionHero
