// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-frequently-asked-questions-js": () => import("./../src/pages/frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-isavasyopanishad-hindi-mein-geetantaran-js": () => import("./../src/pages/isavasyopanishad-hindi-mein-geetantaran.js" /* webpackChunkName: "component---src-pages-isavasyopanishad-hindi-mein-geetantaran-js" */),
  "component---src-pages-our-solutions-js": () => import("./../src/pages/our-solutions.js" /* webpackChunkName: "component---src-pages-our-solutions-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-process-js": () => import("./../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-reflecting-ripples-js": () => import("./../src/pages/reflecting-ripples.js" /* webpackChunkName: "component---src-pages-reflecting-ripples-js" */),
  "component---src-pages-sawal-samay-ke-jawab-zimmedari-ke-js": () => import("./../src/pages/sawal-samay-ke-jawab-zimmedari-ke.js" /* webpackChunkName: "component---src-pages-sawal-samay-ke-jawab-zimmedari-ke-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

