import React from "react"
import "./footer.scss"
import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import twitter from "../../images/icons/twitter.svg"
import instagram from "../../images/icons/instagram.svg"
import facebook from "../../images/icons/facebook.svg"
import linkedin from "../../images/icons/linkedin.svg"

import { OutboundLink } from "gatsby-plugin-google-gtag"

const Footer = ({ children }) => {
  return (
    <footer>
      <ul className="footer-list">
        <div>
          <ul className="footer-list-links">
            <li className="">
              <AniLink
                cover
                bg="#ffffff"
                direction="down"
                className="hover-links"
                to="/about-us"
              >
                About us
              </AniLink>
            </li>
            <li className="">
              <AniLink
                cover
                bg="#ffffff"
                direction="down"
                className="hover-links"
                to="/our-solutions"
              >
                Services
              </AniLink>
            </li>
            <li className="">
              <AniLink
                cover
                bg="#ffffff"
                direction="down"
                className="hover-links"
                to="/process"
              >
                Process
              </AniLink>
            </li>

            <li className="">
              <AniLink
                cover
                bg="#ffffff"
                direction="down"
                className="hover-links"
                to="/frequently-asked-questions"
              >
                FAQs
              </AniLink>
            </li>

            <li className="">
              <OutboundLink
                className="hover-links"
                href="https://medium.com/@wisdomwords"
                target="_blank"
              >
                Blog
              </OutboundLink>
            </li>
            <li className="">
              <AniLink
                cover
                bg="#ffffff"
                direction="down"
                className="hover-links"
                to="/contact-us"
              >
                Contact us
              </AniLink>
            </li>
          </ul>
          <div className="copyright">
            © 2020 wisdom words
            <AniLink
              cover
              bg="#ffffff"
              direction="down"
              style={{ color: "grey" }}
              to="/privacy-policy"
            >
              {" | "}
              Privacy Policy
            </AniLink>
          </div>
        </div>
        <ul className="footer-list-social">
          <li className="">
            <a
              href="https://www.instagram.com/wisdomwordspublishing/"
              target="_blank"
            >
              <img src={instagram} />
            </a>
          </li>
          <li className="">
            <a
              href="https://www.facebook.com/wisdomwordspublishing"
              target="_blank"
            >
              <img src={facebook} />
            </a>
          </li>
          <li className="">
            <OutboundLink
              href="https://twitter.com/PublishingWords"
              target="_blank"
            >
              <img src={twitter} />
            </OutboundLink>
          </li>
          <li className="">
            <a
              href="https://www.linkedin.com/company/wisdom-words-publishing/"
              target="_blank"
            >
              <img src={linkedin} />
            </a>
          </li>
        </ul>
      </ul>

      <div className="copyright bottom">
        © 2020 wisdom words
        <AniLink
          cover
          bg="#ffffff"
          direction="down"
          style={{ color: "grey" }}
          to="/privacy-policy"
        >
          {" | "}
          Privacy Policy
        </AniLink>
      </div>
    </footer>
  )
}

export default Footer
