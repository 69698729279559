import React, { useState } from "react"
// import { Link } from "gatsby"
// import AniLink from "gatsby-plugin-transition-link"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import "./navbar-desktop.scss"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect"

import burger from "../../images/icons/burger.svg"

import logo from "../../images/logo.svg"
//TODO: search for seo friendly navbar and label tags to put here
const NavbarDesktop = () => {
  return [
    <nav className={`desktop-nav-wrapper`}>
      <ul className="left-nav nav-ul">
        <li>
          <AniLink cover bg="#ffffff" direction="down" to="/" className="logo">
            <img src={logo} />
          </AniLink>
        </li>
      </ul>
      <ul className="center-nav nav-ul">
        {/* <li className="">
        <AniLink className="hover-state" to="/">
          About
        </AniLink>
      </li> */}
        <li className="">
          <AniLink
            cover
            bg="#ffffff"
            direction="down"
            className="hover-state"
            to="/about-us"
            activeClassName="active"
          >
            About Us
          </AniLink>
        </li>
        <li className="">
          <AniLink
            cover
            bg="#ffffff"
            direction="down"
            className="hover-state"
            to="/our-solutions"
            activeClassName="active"
          >
            Our Plans
          </AniLink>
        </li>
        <li className="">
          <AniLink
            cover
            bg="#ffffff"
            direction="down"
            className="hover-state"
            to="/process"
            activeClassName="active"
          >
            Our Process
          </AniLink>
        </li>

        {/* <li className="">
          <AniLink
            className="hover-state"
            to="/frequently-asked-questions"
            activeClassName="active"
          >
            FAQs
          </AniLink>
        </li> */}
        <li className="">
          <a
            className="hover-state"
            href="https://medium.com/@wisdomwords"
            target="_blank"
          >
            Blog
          </a>
        </li>
        {/* <li className="">
          <AniLink
            className="hover-state"
            to="/contact-us"
            activeClassName="active"
          >
            Contact
          </AniLink>
        </li> */}
      </ul>
      <ul className="right-nav nav-ul">
        {/* <li className="">
        <AniLink className="hover-state" to="/login">
          Login
        </AniLink>
      </li> */}
        <li className="">
          <AniLink
            cover
            bg="#ffffff"
            direction="down"
            className="button filled"
            to="/contact-us"
            activeClassName="active"
          >
            Contact Us
          </AniLink>
        </li>
      </ul>
    </nav>,
  ]
}

export default NavbarDesktop
