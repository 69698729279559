import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import typewritter from "./asset/typewritter.svg"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect"
import "./hero.scss"

const Hero = () => {
  // useEffect(() => {

  // }, [])
  return (
    <section className="section hero-wrapper">
      <div className="hero">
        <div className="hero-text">
          <h1>
            Your Writing,
            <br />
            Our Publishing.
          </h1>
          <h3>End to End solutions for writers</h3>
          {/* <Link to="/contact-us">
            <button className="button filled">Contact Us</button>
          </Link> */}
        </div>

        <div className="hero-image">
          <img src={typewritter} />
        </div>
      </div>
    </section>
  )
}
export default Hero
