import React, { useState, useEffect } from "react"

import "./review.scss"

import SectionHeader from "../sectionHeader/sectionHeader"

import avatarFemale from "../../images/avatar-female.svg"
import avatarMale from "../../images/avatar-male.svg"
import quoteOpening from "../../images/quote-opening.svg"
import quoteClosing from "../../images/quote-closing.svg"

const reviews = [
  {
    review: (
      <div>
        Wisdom Words are new publishers, and their dynamic approach to guide
        writers, make them better than the old and the established.
        <span className="break" />
        Currently they are publishing my two books. So far they have been most
        commendable.
      </div>
    ),
    image: avatarMale,
    name: "Prof. Prem Mohan Lakhotia",
    helperText: (
      <a
        href="https://www.amazon.in/Ishavasyopanishad-Hindi-Mein-Geetantaran-Mohan-Lakhotia/dp/8194683300/ref=sr_1_fkmr0_1?dchild=1&keywords=ishavasyopanishad+hindi+me&qid=1596624566&sr=8-1-fkmr0"
        target="_blank"
      >
        Ishavasyopanishad-Hindi Mein Geetantaran
      </a>
    ),
  },
  {
    review: (
      <div>
        विज़्डम वर्ड्स ने अपने प्रारम्भिक प्रकाशन क्रम में मेरी चिंतन प्रधान
        पुस्तक को सम्मिलित किया है।
        <span className="break" />
        प्रकाश्य कृति का सम्पादन और रूप-सज्जा से इस प्रकाशक की मौलिक प्रतिभा से
        मैं प्रभावित हुई हूं। मुझ जैसे उदीयमान लेखकों के लिए यह बड़ा संबल है।
      </div>
    ),
    image: avatarFemale,
    name: "Savita Lakhotia",
    helperText: (
      <a
        href="https://www.amazon.in/Sawal-Samay-Ke-Jawab-Zimmedari/dp/8194683319/ref=sr_1_1?crid=3U2Q7UYCC3SGM&dchild=1&keywords=sawal+samay+ke+jawab+zimmedari+ke&qid=1596624671&sprefix=sawal+samay+ke%2Caps%2C390&sr=8-1"
        target="_blank"
      >
        Sawal Samay Ke Jawab Zimmedari Ke
      </a>
    ),
  },
  {
    review: (
      <div>
        Glad to choose the Wisdom Words publishing. My publishing journey with
        such a professional and supportive team is indeed a pleasant experience.
        <span className="break" />
        Thank you for such a delightful book.
      </div>
    ),
    image: avatarFemale,
    name: "Abhimanyu Anant Kamthan",
    helperText: <div>Yaatra - Udaan Jari Hai</div>,
  },
]

const Reviews = () => {
  return (
    <section className="section">
      <SectionHeader head={"Our Happy Authors"} />
      <div className="reviews-wrapper">
        {reviews.map(oneReview => {
          const {
            review: reviewText,
            image: reviewImage,
            name,
            helperText,
          } = oneReview
          return (
            <div className="one-review">
              <div className="review-text">
                <img className="quote opening" src={quoteOpening} />
                {reviewText}
                <img className="quote closing" src={quoteClosing} />
              </div>
              <div className="review-meta">
                {/* <img className="review-face" src={reviewImage} /> */}
                <div className="review-meta-text-wrapper">
                  <div className="name">{name}</div>
                  <div className="help-text">{helperText}</div>
                </div>
              </div>
              {/* <div className="faq-answer">{answer}</div> */}
            </div>
          )
        })}
      </div>
    </section>
  )
}
export default Reviews
