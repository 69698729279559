import React, { useState, useEffect } from "react"
import Layout from "../components/layout"

import "./styles/our-solutions.scss"
import SEO from "../components/seo"

import ContactForm from "../components/contactForm/contactForm"
import Faq from "../components/faq/faq"
import PlanSelector from "../components/plansSelector/plansSelector"
import PlanTable from "../components/planTable/planTable"
import SectionHero from "../components/sectionHero/sectionHero"

import HeroImage from "../images/hero/plans.svg"
const ContactUsPage = () => {
  return (
    <>
      <SEO
        title="Book Publishing and Book Marketing Services"
        description="Get your book published with our exclusive pre-publishing and post-publishing services like editing, cover designing, interior book designing, marketing, and distribution."
      />
      <br />
      <br />
      <SectionHero
        image={HeroImage}
        text={
          <div>
            Publishing <b>plans according </b>
            to your <b>necessity</b>
          </div>
        }
      />
      <PlanTable />
      {/* <Faq /> */}
      <ContactForm subHeading inNewPageTop />
    </>
  )
}
export default ContactUsPage
