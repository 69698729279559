import React from "react"
// import "./books.scss"
// import SectionHeader from "../sectionHeader/sectionHeader"
import Layout from "../components/layout"
import SEO from "../components/seo"

// import bookIsha from "./asset/book-isha.png"
// import bookSawal from "./asset/book-sawal.png"
import ExtraSection from "../components/extraPages/extraPages"

const Faqs = () => {
  return (
    <>
      <SEO
        title="About us"
        description="Writing is a difficult task, but publishing should not be"
      />

      <br />
      <br />
      <ExtraSection page="about" showSidebar={false} />
      {/* <ContactForm subHeading inNewPageTop /> */}
    </>
  )
}
export default Faqs
