import React, { useState, useEffect } from "react"
import Layout from "../components/layout"

import "./styles/contact-us.scss"
import SEO from "../components/seo"

import ContactForm from "../components/contactForm/contactForm"

const ContactUsPage = () => {
  return (
    <>
      <SEO
        title="Contact us"
        description="We believe in nurturing the human relation and thus offer 24 by 7 support via call/Whatsapp/email from the pre-publishing to post publishing journey.- We believe in nurturing the human relation and thus offer 24 by 7 support from pre-publishing to post publishing journey."
      />
      <ContactForm subHeading inNewPageTop />
    </>
  )
}
export default ContactUsPage
