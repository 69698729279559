import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Hero from "../components/hero/hero"
import MainProblems from "../components/mainProblems/mainProblems"
import ProductFeatures from "../components/productFeatures/productFeatures"
import Process from "../components/process/process"
import OurBooks from "../components/ourBooks/ourBooks"
import Faq from "../components/faq/faq"
import Reviews from "../components/review/review"
import ContactForm from "../components/contactForm/contactForm"
import Books from "../components/books/Books"
const IndexPage = () => (
  <>
    <SEO
      title="Publish your book seamlessly"
      description="Wisdom Words Publishing is a guiding hand to budding and creative writers. We are the one-stop for all your publishing needs, be it a pre-publishing or post-publishing."
    />
    <Hero />
    <MainProblems />
    <ProductFeatures />
    <Process />
    <Books />
    {/* <OurBooks /> */}
    <Faq />
    <Reviews />
    <ContactForm />
  </>
)

export default IndexPage
